// Developed by Explorelogy
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import MainSlider from "../../components/common/main-slider"
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline"

import textile_white from "../../images/icons/textile_white.svg"
import intergrate_white from "../../images/icons/intergrate_white.svg"
import advance_white from "../../images/icons/advance_white.svg"

import textile_mint from "../../images/icons/textile_mint.svg"
import intergrate_mint from "../../images/icons/intergrate_mint.svg"
import advance_mint from "../../images/icons/advance_mint.svg"

//import svgs for how it works icons
import intergration_svg from "../../images/visibility/How-it-works/Icons/Integration.svg"
import led_svg from "../../images/visibility/How-it-works/Icons/LED.svg"
import module_svg from "../../images/visibility/How-it-works/Icons/Module.svg"
import pathways_svg from "../../images/visibility/How-it-works/Icons/pathways.svg"
import Platform_Feat_slider from "../../components/common/platform-feat-slider"

//import platform slider
import usb_green from "../../images/visibility/Icons/USB_green.svg"
import usb_white from "../../images/visibility/Icons/USB_white.svg"
import long_green from "../../images/visibility/Icons/Long_standing_green.svg"
import long_white from "../../images/visibility/Icons/Long_standing_white.svg"
import machine_green from "../../images/visibility/Icons/machine_washing_green.svg"
import machine_white from "../../images/visibility/Icons/machine_washing_white.svg"
import visibility_green from "../../images/visibility/Icons/visibility_green.svg"
import visibility_white from "../../images/visibility/Icons/visibility_white.svg"
import form_green from "../../images/visibility/Icons/form_green.svg"
import form_white from "../../images/visibility/Icons/form_white.svg"

const Visibility = ({ data }) => {
  const images = {
    dusk_texure: convertToBgImage(getImage(data.dusk_texure)),
    hero: convertToBgImage(getImage(data.hero)),

    led: convertToBgImage(getImage(data.led)),
    a: getImage(data.grid_1),
    grid_1: convertToBgImage(getImage(data.grid_1)),
    grid_2: convertToBgImage(getImage(data.grid_2)),
    grid_3: convertToBgImage(getImage(data.grid_3)),
    grid_4: convertToBgImage(getImage(data.grid_4)),
    grid_5: convertToBgImage(getImage(data.grid_5)),
    mint_texture: convertToBgImage(getImage(data.mint_texture)),
    slider_1: convertToBgImage(getImage(data.slider_1)),
    slider_2: convertToBgImage(getImage(data.slider_2)),
    slider_3: convertToBgImage(getImage(data.slider_3)),

    //application images

    consumer_img: convertToBgImage(getImage(data.app1)),
    fashion_img: convertToBgImage(getImage(data.app2)),
    health_img: convertToBgImage(getImage(data.app3)),
    performance_img: convertToBgImage(getImage(data.app4)),
    workwear_img: convertToBgImage(getImage(data.app5)),
    patented_img: convertToBgImage(getImage(data.app6)),

    //how it works images

    intergration_img: convertToBgImage(getImage(data.howitworks_1)),
    led_img: convertToBgImage(getImage(data.howitworks_2)),
    module_img: convertToBgImage(getImage(data.howitworks_3)),
    pathways_img: convertToBgImage(getImage(data.howitworks_4)),

    //capability images
    configure_img: convertToBgImage(getImage(data.capability_img1)),
    research_img: convertToBgImage(getImage(data.capability_img2)),
    tech_spec_img: convertToBgImage(getImage(data.capability_img3)),

    //intergrated carousel images

    visiblity_img: convertToBgImage(getImage(data.inter_img1)),
    data_img: convertToBgImage(getImage(data.inter_img2)),
    feedback_img: convertToBgImage(getImage(data.inter_img3)),
    flexible_battery_img: convertToBgImage(getImage(data.inter_img4)),
    heating_img: convertToBgImage(getImage(data.inter_img5)),
    sensing_img: convertToBgImage(getImage(data.inter_img6)),
    textile_circuitry_img: convertToBgImage(getImage(data.inter_img7)),
    touch_textile_img: convertToBgImage(getImage(data.inter_img8)),

    //advance material images

    accoustic_img: convertToBgImage(getImage(data.advance_img1)),
    bio_mimikry_img: convertToBgImage(getImage(data.advance_img2)),
    memory_foam_img: convertToBgImage(getImage(data.advance_img3)),
    pu_foam_img: convertToBgImage(getImage(data.advance_img4)),
    silicon_print_img: convertToBgImage(getImage(data.advance_img5)),
    silicon_img: convertToBgImage(getImage(data.advance_img6)),

    // textile engineering images

    engineered_knit_img: convertToBgImage(getImage(data.textile_img1)),
    inj_mold_img: convertToBgImage(getImage(data.textile_img2)),
    narrow_width_img: convertToBgImage(getImage(data.textile_img3)),

    //
    Patentetd_awarded_first: convertToBgImage(
      getImage(data.Patentetd_awarded_first)
    ),
    science_based: convertToBgImage(getImage(data.science_based)),
  }

  const [activePlatform, setActivePlatform] = useState(0)
  const [activePlatform2, setActivePlatform2] = useState(0)
  const [active, setActive] = useState(0)

  const slider = [
    {
      title: "Engineered Knit",
      content:
        "Leveraging Flat, Circular, and Warp knitting techniques to create complex structures, designs, and modulus.",
      image: images.engineered_knit_img,
      link: "/textile-engineering",
    },
    {
      title: "Narrow Width Fabrics",
      content:
        "Elasticated fabrics and elastomeric yarns engineered to achieve stretch and durability, as well as support complex design and construction.",
      image: images.narrow_width_img,
      link: "/textile-engineering",
    },
    {
      title: "Injection Molding",
      content:
        "A revolutionary form and padding technology for impact protection and the encasing of electronics.",
      image: images.inj_mold_img,
      link: "/textile-engineering",
    },
  ]

  const slider2 = [
    {
      title: "Visibility ",
      content:
        " Next-to-skin LED-based lighting that provides on-demand illumination ensuring visibility and safety, now with RGB capabilities.",
      image: images.visiblity_img,
      link: "/integrated-technologies",
    },
    {
      title: "Thermal Regulation",
      content:
        "Intelligent fabric-based thermal regulation, that can support a variety of functions ranging from microclimate regulation to pain management.",
      image: images.heating_img,
      link: "/integrated-technologies",
    },
    {
      title: "Sensing",
      content:
        "Seamlessly integrated biopotential and biomechanical sensors that allow for the collection of metrics ranging from heart rate to motion.",
      image: images.sensing_img,
      link: "/integrated-technologies",
    },
    {
      title: "Feedback",
      content:
        " Haptics, TENS, and EMS integrated into soft goods for tactile feedback, notification, and chronic pain management.",
      image: images.feedback_img,
      link: "/integrated-technologies",
    },
    {
      title: "Touch Textiles",
      content:
        " Pressure-sensitive textile constructions used to create interactive interfaces, replacing the need for traditional plastic components.",
      image: images.touch_textile_img,
      link: "/integrated-technologies",
    },
    {
      title: "Data Transfer",
      content:
        " Transfer of data and power across fabric substrates with limited flow and interference and low resistance, supporting over 4gbps and 40V at 5A.",
      image: images.data_img,
      link: "/integrated-technologies",
    },
    {
      title: "Flexible Battery",
      content:
        " Adaptable battery for a multitude of wearable uses. Flexible, washable, semi-solid electrolyte construction that serves as the industry’s solution to the growing design needs.",
      image: images.flexible_battery_img,
      link: "/integrated-technologies",
    },

    {
      title: "Textile Circuitry",
      content:
        " A collection of conductive pathways and inlays, interconnects and encapsulation to present technology in a seamless and invisible manner",
      image: images.textile_circuitry_img,
      link: "/integrated-technologies",
    },
  ]

  const slider3 = [
    {
      title: "Bio-mimicking material",
      content:
        "Thermo-responsive polymers and yarns, pine-cone biomimetic yarn for moisture management for improved comfort over extended wear times.",
      image: images.bio_mimikry_img,
      link: "/advanced-materials",
    },
    {
      title: "PU foam-based Injection Molding",
      content:
        "Replacing hard plastic components for flexibility, durability, and seamless integration of sensors",
      image: images.pu_foam_img,
      link: "/advanced-materials",
    },
    {
      title: "Acoustic dampening fabrics",
      content:
        "Certified Acoustic fabrics specifically designed for uninterrupted sound passthrough from materials to product.",
      image: images.accoustic_img,
      link: "/advanced-materials",
    },
    {
      title: "Memory Foam with PU Leather",
      content:
        "Internal and external fabric/leather lamination on foam provides superior comfort and flexibility. Memory foam built for high impact/low frequency.",
      image: images.memory_foam_img,
      link: "/advanced-materials",
    },

    {
      title: "Silicone Printing and Embellishments",
      content:
        "Patented Silicone Application Technology to enable targeted modification of fabric modulus using engineered functional prints for grip and comfort",
      image: images.silicon_print_img,
      link: "/advanced-materials",
    },
  ]

  const howItWorks = [
    {
      title: "LED",
      content:
        "Miniature LEDs calibrated for optimal visibility whilst on the move.",
      image: led_svg,
      cover: images.led_img,
    },
    {
      title: "Pathways",
      content:
        "Silicone encapsulated conductive pathways for on-garment power flow.",
      image: pathways_svg,
      cover: images.pathways_img,
    },
    {
      title: "Module",
      content: "A 16g rechargeable splash-proof battery module.",
      image: module_svg,
      cover: images.module_img,
    },
    {
      title: "Integration",
      content: "Seamless integration into all types of soft goods.",
      image: intergration_svg,
      cover: images.intergration_img,
    },
  ]

  function hello() {
    console.log(howItWorks[active])
  }

  const platform = [
    {
      title: "Technology Specifications",
      content:
        "Our visibility solution comes in two main integratable form factors:",
      content2:
        "Light Guides (Strips) – LEDs embedded in optical grade silicone strips, perfect for safety based visibility applications.",
      content3:
        "Point Source (Dots) – Discrete LEDs embedded directly into fabric which function well for both visibility and aesthetic product differentiation.",
      image: "../../images/brand/symbol-mint.png",
      cover: images.tech_spec_img,
    },
    {
      title: "Configurations",
      content:
        "Color - Can be configured in fixed color, predefined shifting set, or true RGB",
      content2:
        "Shape Size - Near infinite design freedom.  Maximum illumination dimensions to a single panel limited to 11inx11in",
      content3:
        "Thickness - Minimum thinking of 1mm.  Balancing point needs to be made between element thickness and application robustness",
      image: "../../images/brand/symbol-mint.png",
      cover: images.configure_img,
    },
    {
      title: "Research",
      content:
        "A comprehensive research study was carried out by the Queensland university of Technology - Australia.",
      content2: "",
      content3: "",
      image: "../../images/brand/symbol-mint.png",
      cover: images.research_img,
    },
  ]

  const platformSlider = [
    {
      title: "Textile Engineering",
      image: textile_white,
      image_active: textile_mint,
    },
    {
      title: "Integrated Technologies",
      image: intergrate_white,
      image_active: intergrate_mint,
    },
    {
      title: "Advanced Material",
      image: advance_white,
      image_active: advance_mint,
    },
  ]

  const platform_feat_slider_array = [
    {
      icon: long_green,
      icon_white: long_white,
      title: "Long-Lasting",
      desc: "8 hours of continuous illumination",
    },
    {
      icon: machine_green,
      icon_white: machine_white,
      title: "Machine Washable",
      desc: "50 wash cycle certified technology system",
    },
    {
      icon: usb_green,
      icon_white: usb_white,
      title: "USB Rechargeable",
      desc: "Battery that’s splash proof and rechargeable",
    },
    {
      icon: visibility_green,
      icon_white: visibility_white,
      title: "Visibility",
      desc: "More than 450 feet visibility in low light conditions",
    },
    {
      icon: form_green,
      icon_white: form_white,
      title: "Form factor",
      desc: " Profile can be reduced up to 1mm for performance application",
    },
  ]

  return (
    <Layout>
      <Seo title="Visibility" />
      <section>
        <BackgroundImage {...images.hero} className="h-screen bg-dusk">
          <div className="container h-full mx-auto">
            <div className="flex h-full items-center">
              <Fade bottom cascade>
                <div className="flex flex-col ">
                  <div className="sm:text-6xl text-4xl  sm:text-left text-center  mb-1 text-white">
                    Visibility
                  </div>
                  <div className="sm:text-2xl text-xl sm:max-w-lg text-white font-light mt-4  sm:text-left text-center">
                    Comfort, performance and on- demand lighting in a single
                    package
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <div className="bg-chalk">
          <div className="container mx-auto py-20">
            <div className="flex flex-col items-center">
              <Fade bottom cascade>
                <div>
                  <div className="text-mint mb-4 uppercase text-center ">
                    ACCELERATING PERFORMANCE AND SAFETY
                  </div>
                  <div className="text-4xl text-center font-light mb-4 text-dusk">
                    Customized Illuminated Solutions
                  </div>
                  <div className="text-2xl font-light text-center max-w-5xl leading-snug text-dusk">
                    Our groundbreaking textile-integrated illumination
                    technology that is revolutionizing the future of apparel,
                    setting new standards for safety, visibility, and product
                    differentiation. Our platform offers more than 450 feet of
                    visibility in low light conditions with over 8 hours of
                    continuous illumination.
                  </div>
                  <div className="text-2xl font-light text-center max-w-5xl leading-snug mt-4 text-dusk">
                    More recent developments have helped us crack the code on
                    RGB color optionality and gradients, built to allow brands
                    to better engage with their customers aesthetically.
                  </div>
                </div>
              </Fade>
              <div className="mt-24">
                <Fade bottom>
                  <div className="text-4xl text-center font-light mb-4 text-dusk">
                    Platform Features
                  </div>
                </Fade>

                <div className="mt-8 grid grid-cols-1">
                  <Platform_Feat_slider
                    platform_feat_slider_array={platform_feat_slider_array}
                    mint
                  />
                </div>
              </div>
            </div>
            <div className="mt-24">
              <Fade bottom cascade>
                <div>
                  <div className="text-4xl font-light text-dusk mb-4 sm:text-left text-center">
                    How it works
                  </div>
                  <div className="text-2xl font-light text-dusk max-w-4xl leading-snug sm:text-left text-center">
                    The most complete next-to-skin lighting system that is
                    machine washable, power-efficient, and keeps you safe.
                  </div>
                </div>
              </Fade>
              <div className="mt-14">
                <div className="flex items-center justify-center lg:gap-32 gap-5 flex-wrap">
                  {howItWorks.map((item, index) => {
                    return (
                      <Fade bottom cascade key={index}>
                        <div>
                          <div
                            className={
                              active == index
                                ? "font-firs-semibold cursor-pointer text-dusk sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-3 border-dusk duration-200"
                                : "font-firs-semibold cursor-pointer text-dusk sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-3 border-transparent duration-200"
                            }
                            onClick={() => {
                              setActive(index)
                              hello()
                            }}
                          >
                            {item.title}
                          </div>
                        </div>
                      </Fade>
                    )
                  })}
                </div>
                <div className="mt-8">
                  <Fade bottom>
                    <div className="flex lg:flex-row flex-col sm:text-left text-center">
                      <BackgroundImage
                        {...howItWorks[active].cover}
                        className="w-full lg:w-2/5 py-44"
                      ></BackgroundImage>
                      <div className="w-full lg:w-3/5 bg-dusk p-14 flex flex-col justify-center">
                        <div>
                          <div className="flex sm:justify-start justify-center">
                            <div>
                              {/* <img
                                src={howItWorks[active].image}
                                width={80}
                                alt="Softmatter"
                                className="cursor-pointer"
                              /> */}
                            </div>
                          </div>
                        </div>
                        <div className="text-2xl font-light text-white">
                          {howItWorks[active].title}
                        </div>
                        <div className="text-xl font-light mt-2 text-white max-w-lg">
                          {howItWorks[active].content}
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-mint overflow-hidden">
          <div className="flex xl:flex-row flex-col ">
            <div className="w-full xl:w-1/2 xl:hidden block">
              <Fade right>
                <div className="w-full ">
                  <BackgroundImage
                    {...images.Patentetd_awarded_first}
                    className="py-36 sm:py-60 md:py-96 xl:py-72 "
                  ></BackgroundImage>
                </div>
              </Fade>
            </div>
            <div className="xl:w-1/2 w-full xl:pl-36 pl-10 flex flex-col justify-center">
              <Fade>
                <div className="text-white pr-10 sm:text-left text-center xl:py-0 py-10">
                  <div className="text-4xl text-dusk font-light mb-3 leading-tight">
                    Patented. Awarded. Different.
                  </div>
                  <div className="text-base xl:max-w-xl max-w-2xl mb-4">
                    Our platform is recognized as an illumination technology
                    that has revolutionized the future of smart soft goods, and
                    is a recipient of a CES 2017 Honoree Award and an ISPO 2018
                    Silver Award.
                  </div>
                  <div className="text-base xl:max-w-xl max-w-2xl ">
                    Our Visibility platform came to life at a time when
                    reflectives were the only visibility and safety solution for
                    night-time runners and cyclists. Far from the optimal
                    solution, runners and cyclists were still at risk of
                    accidents due to fast-moving motorists, or the lack of
                    external light sources. Driven by this knowledge, we
                    conducted research and collated consumer insights to develop
                    our patented active lighting technology – ‘Firefly’. Using
                    an LED-based system to improve low-light visibility, the
                    platform increased wearer visibility range to over 200
                    meters and negated the need for an external light source,
                    drastically improving wearer safety.
                  </div>
                </div>
              </Fade>
            </div>
            <div className="w-full xl:w-1/2 xl:block hidden">
              <Fade right>
                <div className="w-full ">
                  <BackgroundImage
                    {...images.Patentetd_awarded_first}
                    className="py-36 sm:py-60 md:py-96 xl:py-72 "
                  ></BackgroundImage>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-mint">
          <div className="flex xl:flex-row flex-col ">
            <div className="w-full xl:w-1/2">
              <Fade left>
                <div className="w-full">
                  <BackgroundImage
                    {...images.science_based}
                    className="py-36 sm:py-60 md:py-96 xl:py-72"
                  ></BackgroundImage>
                </div>
              </Fade>
            </div>
            <div className="xl:w-1/2 w-full xl:pl-36 pl-10 flex flex-col justify-center">
              <Fade>
                <div className="text-white pr-10 sm:text-left text-center xl:py-0 py-10">
                  <div className="text-4xl text-dusk font-light mb-3 leading-tight">
                    Science based Visibility
                  </div>
                  <div className="text-base max-w-xl mb-4">
                    Our Firefly solution comes in two main integratable form
                    factors. Light Guides (Strips) and Point Source (Dots)
                    embedded directly into fabric which function well for both
                    visibility and aesthetic product differentiation.
                  </div>
                  <div className="text-base max-w-xl mb-4">
                    Our wiring consists of safely encapsulated conductive
                    pathways that act as the powering solution for products.
                    Working in tandem with the soft-good, we are able to use our
                    own powering solution (a splash-proof 16g battery module),
                    or link this up to a powering solution of your choice.
                  </div>
                  <div className="text-base max-w-xl">
                    More recent developments have helped us crack the code on
                    RGB color optionality and gradients, built to allow brands
                    to better engage with their customers aesthetically.
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      {/* <section>
        <div className="lg:pb-0 pb-20 lg:py-24 bg-mint overflow-hidden sm:text-left text-center">
          <div className="container mx-auto flex lg:flex-row flex-col items-center">
            <div className="lg:hidden bloc pt-5">
              <StaticImage
                placeholder="blurred"
                src="../../images/visibility/Patentetd_awarded.png"
                alt="Softmatter"
                width={700}
              />
            </div>
            <Fade bottom cascade>
              <div className="w-full lg:w-1/2 lg:pt-0 pt-10 z-10">
                <div className="text-4xl font-light mb-4 text-dusk ">
                  Patented. Awarded. Different.
                </div>
                <div className="text-xl font-light max-w-5xl leading-snug text-white">
                  Our platform is recognized as an illumination technology that
                  has revolutionized the future of smart soft goods, and is a
                  recipient of a CES 2017 Honoree Award and an ISPO 2018 Silver
                  Award.
                </div>
                <div className="text-xl font-light max-w-5xl leading-snug mt-4 text-white">
                  Our Visibility platform came to life at a time when
                  reflectives were the only visibility and safety solution for
                  night-time runners and cyclists. Far from the optimal
                  solution, runners and cyclists were still at risk of accidents
                  due to fast-moving motorists, or the lack of external light
                  sources. Driven by this knowledge, we conducted research and
                  collated consumer insights to develop our patented active
                  lighting technology – ‘Firefly’. Using an LED-based system to
                  improve low-light visibility, the platform increased wearer
                  visibility range to over 200 meters and negated the need for
                  an external light source, drastically improving wearer safety.
                </div>
              </div>
            </Fade>
            <Fade right>
              <div className="lg:block hidden absolute right-0">
                <StaticImage
                  placeholder="blurred"
                  src="../../images/visibility/Patentetd_awarded.png"
                  alt="Softmatter"
                  width={700}
                />
              </div>
            </Fade>
          </div>
        </div>
      </section> */}

      {/* <section>
        <div className="lg:pb-0 pb-20 lg:py-24 bg-mint overflow-hidden sm:text-left text-center">
          <div className="container mx-auto flex lg:flex-row flex-col items-center mb-24">
            <div className="lg:hidden block">
              <StaticImage
                placeholder="blurred"
                src="../../images/visibility/science_based.png"
                alt="Softmatter"
                width={700}
              />
            </div>
            <Fade bottom cascade>
              <div className="w-full lg:w-1/2 lg:pt-0 pt-10 z-10">
                <div className="text-4xl font-light mb-4 text-dusk ">
                  Science based Visibility
                </div>
                <div className="text-xl font-light max-w-5xl leading-snug text-white">
                  Our Firefly solution comes in two main integratable form
                  factors. Light Guides (Strips) and Point Source (Dots)
                  embedded directly into fabric which function well for both
                  visibility and aesthetic product differentiation.
                </div>
                <div className="text-xl font-light max-w-5xl leading-snug mt-4 text-white">
                  Our wiring consists of safely encapsulated conductive pathways
                  that act as the powering solution for products. Working in
                  tandem with the soft-good, we are able to use our own powering
                  solution (a splash-proof 16g battery module), or link this up
                  to a powering solution of your choice.
                </div>
                <div className="text-xl font-light max-w-5xl leading-snug mt-4 text-white">
                  More recent developments have helped us crack the code on RGB
                  color optionality and gradients, built to allow brands to
                  better engage with their customers aesthetically.
                </div>
              </div>
            </Fade>
            <Fade left>
              <div className="lg:block hidden absolute right-0">
                <StaticImage
                  placeholder="blurred"
                  src="../../images/visibility/science_based.png"
                  alt="Softmatter"
                  width={700}
                />
              </div>
            </Fade>
          </div>
        </div>
      </section> */}

      <section>
        <div className="bg-dusk">
          <div className="px-5 py-24">
            <Fade bottom cascade>
              <div className="text-white">
                <div className="text-center text-2xl font-firs-thin  mb-4">
                  Our Lighting
                </div>
                <div className="text-center text-5xl md:text-6xl mb-4">
                  Applications
                </div>
                <div className="text-2xl mt-2 text-center max-w-4xl mx-auto">
                  Embracing the power of fabric-based active lighting, we have
                  designed solutions to varied problems spanning applications
                  and industries.
                </div>
              </div>
            </Fade>
            <div className="mt-32 overflow-hidden">
              <div className="flex lg:flex-row flex-col ">
                <div className="w-full lg:w-1/2 lg:hidden block">
                  <Fade right>
                    <div className="w-full ">
                      <BackgroundImage
                        {...images.consumer_img}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:pl-36 pl-10 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white pr-10 sm:text-left text-center lg:py-0 py-10">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Consumer Technology
                      </div>
                      <div className="text-base lg:max-w-xl max-w-2xl ">
                        LED Integration intro interactive devices and
                        accessories. We have the capabilities to discretely
                        integrated RGB illumination to build UI needs such as
                        notifications in devices.
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                          Request Information
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
                <div className="w-full lg:w-1/2 lg:block hidden">
                  <Fade right>
                    <div className="w-full ">
                      <BackgroundImage
                        {...images.consumer_img}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col ">
                <div className="w-full lg:w-1/2">
                  <Fade left>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.health_img}
                        className="py-60"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:pl-36 pl-10 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white pr-10 sm:text-left text-center lg:py-0 py-10">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Consumer Health
                      </div>
                      <div className="text-base max-w-xl">
                        From the integration of lighting into soft goods for
                        notification and indicative purposes, to the creation of
                        wearable, portable phototherapy devices.
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                          Request Information
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col ">
                <div className="w-full lg:hidden block">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.workwear_img}
                        className="py-60"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:pl-36 pl-10 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white pr-10 sm:text-left text-center lg:py-0 py-10">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Workwear
                      </div>
                      <div className="text-base max-w-xl">
                        On the job protection to workers in high-risk, low-light
                        conditions, with sensors and LED lights built directly
                        into their protective gear
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                          Request Information
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
                <div className="w-full lg:w-1/2 lg:block hidden">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.workwear_img}
                        className="py-60"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col">
                <div className="w-full lg:w-1/2">
                  <Fade left>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.performance_img}
                        className="py-60"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:pl-36 pl-10 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white pr-10 sm:text-left text-center lg:py-0 py-10">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Performance and Outdoor
                      </div>
                      <div className="text-base max-w-xl">
                        Keeps you visible and protected when hiking, mountain
                        climbing and skiing in low-light conditions, allowing
                        you to remain active and safe in tough conditions.
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                          Request Information
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col">
                <div className="w-full lg:hidden block">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.fashion_img}
                        className="py-60 bg-gray-400"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:pl-36 pl-10 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white pr-10 sm:text-left text-center lg:py-0 py-10">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Fashion
                      </div>
                      <div className="text-base max-w-xl">
                        Easily integratable into haute couture allowing
                        designers to explore and illuminate their creativity –
                        from the illumination of logos, to mood based lighting.
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                          Request Information
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
                <div className="w-full lg:w-1/2 lg:block hidden">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.fashion_img}
                        className="py-60 bg-gray-400"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>

              <div className="flex lg:flex-row flex-col">
                <div className="w-full lg:w-1/2 ">
                  <Fade left>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.patented_img}
                        className="py-60"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:pl-36 pl-10 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white pr-10 sm:text-left text-center lg:py-0 py-10">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Patented. Awarded. Different.
                      </div>
                      <div className="text-base max-w-xl">
                        Firefly is recognized as an illumination technology that
                        has revolutionized the future of smart soft goods, and
                        is a recipient of a CES 2017 Honoree Award and an ISPO
                        2018 Silver Award.
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                          Request Information
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="py-20 bg-chalk ">
          <div className="container mx-auto">
            <Fade top>
              <div className="text-4xl font-light text-center text-dusk mb-4">
                Platform Capabilities
              </div>
            </Fade>
            <Fade bottom>
              <div className="text-2xl font-light text-center mx-auto text-dusk max-w-4xl leading-snug">
                The most complete next-to-skin lighting system that is machine
                washable, power efficient, and safe on body
              </div>
            </Fade>
            <div className="mt-14">
              <div className="flex items-center justify-center lg:gap-32 sm:gap-0">
                {platform.map((item, index) => {
                  return (
                    <Fade bottom cascade key={index}>
                      <div>
                        <div
                          className={
                            activePlatform2 == index
                              ? "font-firs-semibold cursor-pointer text-dusk sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-2 border-dusk duration-200"
                              : "font-firs-semibold cursor-pointer text-dusk sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-2 border-transparent duration-200"
                          }
                          onClick={() => {
                            setActivePlatform2(index)
                            hello()
                          }}
                        >
                          {item.title}
                        </div>
                      </div>
                    </Fade>
                  )
                })}
              </div>
              <div className="mt-8">
                <Fade bottom>
                  <div className="flex lg:flex-row flex-col sm:text-left text-center">
                    <BackgroundImage
                      {...platform[activePlatform2].cover}
                      className="w-full lg:w-2/5 py-44"
                    ></BackgroundImage>
                    <div className="w-full lg:w-3/5 bg-dusk p-8 sm:p-14 flex flex-col justify-center">
                      <div className="text-2xl font-light text-white">
                        {platform[activePlatform2].title}
                      </div>
                      <div className="text-xl font-light mt-4 text-white max-w-2xl">
                        {platform[activePlatform2].content}
                      </div>
                      <div className="text-xl font-light mt-4 text-white max-w-2xl">
                        {platform[activePlatform2].content2}
                      </div>
                      <div className="text-xl font-light mt-4 text-white max-w-2xl">
                        {platform[activePlatform2].content3}
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-dusk py-24 overflow-hidden relative">
          <div className="container mx-auto">
            <div className="mb-20">
              <Fade top>
                <div className="text-center text-2xl font-firs-thin text-white mb-4">
                  TECHNOLOGY WOVEN IN
                </div>
              </Fade>
              <Fade bottom>
                <div className="text-center text-4xl text-white">
                  Explore All Platforms
                </div>
              </Fade>
            </div>
            <div className="flex lg:flex-row flex-col mb-72 lg:mb-44">
              <div className="w-full lg:w-4/12">
                <div className="flex gap-5 lg:gap-10 lg:flex-col justify-center flex-row">
                  {platformSlider.map((item, index) => {
                    return (
                      <Fade bottom cascade key={index}>
                        <div className=" text-white mb-14">
                          <div className="uppercase text-xl lg:block flex justify-center font-light mb-4">
                            {activePlatform == index ? (
                              <>
                                <img
                                  src={item.image_active}
                                  width={60}
                                  alt="Softmatter"
                                  className="cursor-pointer sm:w-16 w-10"
                                  onClick={() => setActivePlatform(index)}
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={item.image}
                                  width={60}
                                  alt="Softmatter"
                                  className="cursor-pointer sm:w-16 w-10"
                                  onClick={() => setActivePlatform(index)}
                                />
                              </>
                            )}
                          </div>
                          <div
                            className={
                              activePlatform == index
                                ? "mt-6  text-xs sm:text-xl text max-w-sm text-mint duration-100  lg:text-left text-center"
                                : "mt-6  text-xs sm:text-xl text max-w-sm duration-100 lg:text-left text-center"
                            }
                          >
                            <span
                              className={
                                activePlatform == index
                                  ? "pb-2 border-b-0 lg:border-b-2 border-mint duration-100 cursor-pointer"
                                  : "pb-2 duration-100 cursor-pointer hover:border-b-2 border-white"
                              }
                              onClick={() => setActivePlatform(index)}
                            >
                              {item.title}
                            </span>
                          </div>
                        </div>
                      </Fade>
                    )
                  })}
                </div>
              </div>
              <div className="w-full lg:w-7/12 pb-96 mb-32 sm:mb-24 lg:mb-0 lg:pb-24">
                <div className="text-white absolute w-full">
                  {activePlatform == 0 && (
                    <>
                      <MainSlider slider_array={slider} hover />
                    </>
                  )}
                  {activePlatform == 1 && (
                    <>
                      <MainSlider slider_array={slider2} hover />
                    </>
                  )}
                  {activePlatform == 2 && (
                    <>
                      <MainSlider slider_array={slider3} hover />
                    </>
                  )}

                  <div className="flex pr-0 sm:pr-20 lg:pr-96 mr-0 sm:mr-20 md:mr-60 xl:mr-80 2xl:mr-96 justify-center items-center mt-14">
                    <div className="text-white my-custom-pagination-div ml-0 xl:ml-16 2xl:ml-24"></div>
                    <div className="flex justify-center mr-20">
                      <div className="swiper-button-prev-square py-3 px-4 cursor-pointer flex items-center justify-center bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                        <ChevronLeftIcon width={20} />
                      </div>
                      <div className="swiper-button-next-square py-3 px-4 cursor-pointer bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                        <ChevronRightIcon width={20} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="flex lg:flex-row flex-col">
          <div className="w-full lg:w-1/2">
            <BackgroundImage
              {...images.mint_texture}
              className="h-full bg-cover lg:py-0 py-40"
            >
              <Zoom>
                <div className="flex h-full items-center justify-center">
                  <StaticImage
                    src="../images/brand/logo-symbol.png"
                    placeholder="blurred"
                    alt="Softmatter"
                    width={180}
                    className="lg:w-auto w-20"
                  />
                </div>
              </Zoom>
            </BackgroundImage>
          </div>
          <div className="w-full lg:w-1/2">
            <Fade bottom cascade>
              <div className="bg-chalk py-20 lg:py-56 text-dusk px-10">
                <div className="text-center  text-5xl sm:text-6xl mb-4">
                  How to Engage
                </div>
                <div className="text-xl mt-2 text-center max-w-xl mx-auto">
                  At Softmatter, our dynamic team has industry-leading expertise
                  in smart textiles, from design to manufacture. We help you
                  strategically enhance your offering so that it has a profound,
                  global impact.
                </div>
                <div className="text-2xl mt-5 text-center max-w-4xl mx-auto">
                  Interested in enabling your idea?
                </div>
                <div className="flex justify-center mt-8">
                  <Link to="/contact">
                    <button className="px-8 py-4 bg-mint  hover:bg-mint/90 duration-200 text-white text-2xl">
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query VisibilityImages {
    dusk_texure: file(relativePath: { eq: "textures/dusk_texure.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    mint_texture: file(relativePath: { eq: "home/mint_texture.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_1: file(relativePath: { eq: "home/slider/slider_1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_2: file(relativePath: { eq: "home/slider/slider_2.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_3: file(relativePath: { eq: "home/slider/slider_3.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    hero: file(relativePath: { eq: "visibility/hero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    patented_awarded: file(
      relativePath: { eq: "visibility/Patentetd_awarded.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    Patentetd_awarded_first: file(
      relativePath: { eq: "visibility/Patentetd_awarded_first.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    science_based: file(relativePath: { eq: "visibility/science_based.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    app1: file(
      relativePath: { eq: "visibility/applications/Consumer_Tech.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    app2: file(relativePath: { eq: "visibility/applications/Fashion.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    app3: file(relativePath: { eq: "visibility/applications/Health.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    app4: file(
      relativePath: { eq: "visibility/applications/Perfromance.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    app5: file(
      relativePath: { eq: "visibility/applications/Visibility_workwear.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    app6: file(relativePath: { eq: "visibility/applications/Patented.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    howitworks_1: file(
      relativePath: { eq: "visibility/How-it-works/Integration.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    howitworks_2: file(
      relativePath: { eq: "visibility/How-it-works/LED.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    howitworks_3: file(
      relativePath: { eq: "visibility/How-it-works/Module.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    howitworks_4: file(
      relativePath: { eq: "visibility/How-it-works/Pathways.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    capability_img1: file(
      relativePath: {
        eq: "visibility/Platform-Capabilities/Configurations.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    capability_img2: file(
      relativePath: { eq: "visibility/Platform-Capabilities/Research.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    capability_img3: file(
      relativePath: { eq: "visibility/Platform-Capabilities/Tech_Specs.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img1: file(
      relativePath: { eq: "platform-carousel/Integrated/Visibility.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img2: file(
      relativePath: { eq: "platform-carousel/Integrated/Data.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img3: file(
      relativePath: { eq: "platform-carousel/Integrated/Feedback.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img4: file(
      relativePath: { eq: "platform-carousel/Integrated/Flexible_Battery.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img5: file(
      relativePath: { eq: "platform-carousel/Integrated/Heating.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img6: file(
      relativePath: { eq: "platform-carousel/Integrated/Sensing.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img7: file(
      relativePath: { eq: "platform-carousel/Integrated/Textile_circuitry.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img8: file(
      relativePath: { eq: "platform-carousel/Integrated/Touch_textiles.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img1: file(
      relativePath: { eq: "platform-carousel/Advanced-Materials/Accoustic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img2: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/Bio_Mimikry_Material.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img3: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/Memory_Foam_with_PU_Leather.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img4: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/PU_foam_based_Injection_Molding.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img5: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/Silicone_Printing.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img6: file(
      relativePath: { eq: "platform-carousel/Advanced-Materials/Silicone.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    main_carousel1: file(
      relativePath: { eq: "Main_category_carousel/Advanced-Material.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    main_carousel2: file(
      relativePath: { eq: "Main_category_carousel/Integrated.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    main_carousel3: file(
      relativePath: { eq: "Main_category_carousel/Textile-Engineering.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    textile_img1: file(
      relativePath: {
        eq: "platform-carousel/Textile-Engineering/Engineered_Knit.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    textile_img2: file(
      relativePath: {
        eq: "platform-carousel/Textile-Engineering/Injection_Molding.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    textile_img3: file(
      relativePath: {
        eq: "platform-carousel/Textile-Engineering/Nawrrow_Width.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`

export default Visibility
